import { Grid } from "@mui/material";
import React from "react";
import "../../styles/formationForm.scss";
import { containsFormation, sortFormationsByYear } from "../../utils/calendarTools";
import CalendarItem from "./CalendarItem";
import FormationDialog from "./FormationDialog";
import { Formation } from "./formations/formations";
interface YearsProps {
    formations: Formation[];
}

export default function Calendar(props: YearsProps) {
    const [dialogFormation, setDialogFormation] = React.useState();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (formation: any) => {
        setDialogFormation(formation);
        setOpen(true);
    };

    return (
        <>
            {sortFormationsByYear(props.formations).map((year: any) => (
                <Grid
                    container
                    spacing={4}
                    className="year_grid"
                    sx={{ marginLeft: 5, marginRight: 5, alignContent: "center", margin: 0 }}
                    key={year.year + Math.random()}
                >
                    <Grid item xs={12}>
                        <h2 className="year" key={"year " + year.year + Math.random()}>
                            {year.year}
                        </h2>
                    </Grid>
                    {year.months.map(
                        (month: any) =>
                            month.formations.length > 0 &&
                            containsFormation(month.formations) && (
                                <Grid item xs={12} md={3}>
                                    <h2 className="month" key={month.month + Math.random()}>
                                        {month.month}
                                    </h2>
                                    {month.formations
                                        .sort((a: any, b: any) => a.date - b.date)
                                        .map((formation: any) => (
                                            <CalendarItem
                                                key={formation.title + Math.random()}
                                                formation={formation}
                                                handleClickOpen={() => handleClickOpen(formation)}
                                            />
                                        ))}
                                </Grid>
                            )
                    )}
                </Grid>
            ))}
            {dialogFormation && (
                <FormationDialog
                    formation={dialogFormation}
                    open={open}
                    setOpen={setOpen}
                    setFormation={setDialogFormation}
                />
            )}
        </>
    );
}
