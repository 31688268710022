import { Box, Container, Grid } from "@mui/material";
import { imagesTherapy } from "../../ressources/Images";
import "../../styles/therapy.scss";
import SocialMediasFollow from "../SocialFollow/SocialMediasFollow";
export default function Therapy() {
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={3} lg={3}>
                    <img src="/medias/arbre.jpg" alt="background" className="logo" />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={9}
                    xl={9}
                    display={{ xs: "none", md: "block", lg: "block", xl: "block" }}
                >
                    <div className="therapy_intro">
                        <h1 className="therapy_intro_title">Thérapie holistique</h1>
                        <p className="therapy_intro_content">
                            Une expérience de près de 20 ans dans le domaine du leadership et du
                            coaching d’équipes ainsi que plusieurs formations en thérapies
                            énergétiques et vibratoires dans le domaine du bien-être me permettent
                            de vous proposer un accompagnement personnalisé sur le chemin de la
                            prise de conscience, du changement et de la renaissance. Je vous propose
                            un retour à votre essence profonde en conscience et à votre rythme. Ce
                            que vous désirez profondément ne doit pas rester un rêve, ne soyez plus
                            spectateur de votre vie mais bien l’acteur !
                        </p>
                        <span className="citation">
                            «{" "}
                            <b>
                                <i className="citation_text">
                                    Tout changement est difficile au début, compliqué au milieu et
                                    magnifique à la fin{" "}
                                </i>
                            </b>
                            » <br />
                            <b>Robin Sharma.</b>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} display={{ xs: "block", md: "none", lg: "none", xl: "none" }}>
                    <div className="therapy_intro" style={{ marginTop: 0 }}>
                        <h1 className="therapy_intro_title">Thérapie holistique</h1>
                        <p className="therapy_intro_content">
                            Une expérience de près de 20 ans dans le domaine du leadership et du
                            coaching d’équipes ainsi que plusieurs formations en thérapies
                            énergétiques et vibratoires dans le domaine du bien-être me permettent
                            de vous proposer un accompagnement personnalisé sur le chemin de la
                            prise de conscience, du changement et de la renaissance. Je vous propose
                            un retour à votre essence profonde en conscience et à votre rythme. Ce
                            que vous désirez profondément ne doit pas rester un rêve, ne soyez plus
                            spectateur de votre vie mais bien l’acteur !
                        </p>
                        <span className="citation">
                            «{" "}
                            <b>
                                <i className="citation_text">
                                    Tout changement est difficile au début, compliqué au milieu et
                                    magnifique à la fin{" "}
                                </i>
                            </b>
                            » <br />
                            <b>Robin Sharma.</b>
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Container component="section" sx={{ mt: 8, mb: 2 }}>
                        <Box sx={{ mt: 7, display: "flex", flexWrap: "wrap" }}>
                            <img
                                src={imagesTherapy[0].url}
                                alt="therapy"
                                className="imageTherapy"
                            />
                        </Box>
                    </Container>
                </Grid>
                <SocialMediasFollow />
            </Grid>
        </>
    );
}
