import { useCallback, useEffect, useState } from "react";
import ChangingImage from "./ChangingImage";

interface Image {
    url: string;
    width: string;
    title: string;
}
interface ChangingImageProps {
    category: string;
    redirection: () => void;
}

interface PropsImages {
    images: Array<Image>;
    changingImageProps: ChangingImageProps;
    delayInMilliSeconds: number;
}

export default function GlobalChangingImage(props: PropsImages) {
    const allImages = props.images;
    const changingImageProps = props.changingImageProps;
    const [image, setImage] = useState<Image>(allImages[0]);
    const [index, setIndex] = useState(0);
    const delay = props.delayInMilliSeconds;
    const changeImage = useCallback(() => {
        var currentIndex = index.valueOf();
        setImage(allImages[currentIndex]);
        currentIndex >= allImages.length - 1 ? (currentIndex = 0) : currentIndex++;
        setIndex(currentIndex);
    }, [index, setIndex, setImage, allImages]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            changeImage();
        }, delay);

        return () => clearInterval(intervalId);
    }, [changeImage, delay]);
    return (
        <ChangingImage
            image={image}
            category={changingImageProps.category}
            redirection={changingImageProps.redirection}
        />
    );
}
