import { Form } from "react-router-dom";

export interface IFormation {
    date: Date;
    description: string;
    endTime: string;
    startTime: string;
    title: string;
    location: string;
    price: number;
}

export class Formation implements IFormation {
    /**
     *
     */
    constructor(
        date: Date,
        description: string,
        endTime: string,
        startTime: string,
        title: string,
        location: string,
        price: number
    ) {
        this.date = date;
        this.description = description;
        this.endTime = endTime;
        this.startTime = startTime;
        this.title = title;
        this.location = location;
        this.price = price;
    }
    date: Date;
    description: string;
    endTime: string;
    startTime: string;
    title: string;
    location: string;
    price: number;
}

//10 décembre = 1670663820
//11 décembre = 1670750220
//4 décémbre = 1670142600

// export const rfFormations: IFormation[] = [
//     new Formation(
//         new Date("2022-12-04"),
//         "❌(ANNULÉ) Cet atelier animé par Romina vous emmènera au pays enchanteur des minéraux et de leur pouvoir vibratoire.Vous découvrirez également leur pouvoir caché, leur côté magique et comment en bénéficier. Comment les choisir et quels rituels simples peuvent être accomplis grâce à ces cadeaux de notre terre mère.Prix de l’atelier : 50 euros boissons comprises.",
//         "12:30",
//         "09:30",
//         "❌(ANNULÉ) Vibrations et secrets des pierres de soin",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         50
//     ),
//     new Formation(
//         new Date("2022-12-10"),
//         "La période des fêtes se veut festive et la boutique RF Therapy vous proposera un marché de Noël festif et convivial !Vous y trouverez les idées cadeaux pour gâter les personnes qui vous sont chères en dégustant des mignardises de Noël et une bonne boisson chaude.",
//         "17:00",
//         "10:00",
//         "Marché de Noël de la boutique RF Therapy ",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         -1
//     ),
//     new Formation(
//         new Date("2022-12-11"),
//         "La période des fêtes se veut festive et la boutique RF Therapy vous proposera un marché de Noël festif et convivial !Vous y trouverez les idées cadeaux pour gâter les personnes qui vous sont chères en dégustant des mignardises de Noël et une bonne boisson chaude.",
//         "17:00",
//         "10:00",
//         "Marché de Noël de la boutique RF Therapy ",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         -1
//     ),
//     //2023
//     new Formation(
//         new Date("2023-01-07"),
//         "Entrée gratuite 🎉🎈",
//         "20:00",
//         "18:00",
//         "Soirée de présentation du programme de formations 2023",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         -1
//     ),
//     new Formation(
//         new Date("2023-02-05"),
//         "Le tarot , ce jeu de carte qui suscite en nous mystère, magie et fascination se révèle un formidable outil de développement personnel. Loin des clichés de madame Irma, la cartomancienne qui dans sa roulote vous prédisait l’avenir, ce jeu de cartes est au contraire un puissant allié qui vous permet de mettre en évidence vos points forts et ceux à travailler. Au travers de son histoire et de la magie de ses 22 arcanes majeurs, vous découvrirez comment le Tarot vous guidera dans la connaissance de vous-même et de votre chemin de vie. Nous apprendrons à l’utiliser de manière simple afin qu’il vous épaule au mieux dans vos questionnements quotidiens.",
//         "17:00",
//         "10:00",
//         "'Découverte du Tarot de Marseille comme outil de développement personnel' : partie 1",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),

//     new Formation(
//         new Date("2023-02-11"),
//         "Cette pratique de thérapie brève vous permettra de mettre en évidence des conflits personnels et intergénérationnels issus de l’inconscient familial.Il est possible de consteller une émotion, une situation, un conflit,…Ce rendez-vous de groupe permettra aux différents intervenants, le constellateur, le constellé et les figurants de travailler une problématique choisie. Le champ morphique donne la possibilité à tous d’entrer dans le monde merveilleux de la constellation et de trouver des réponses et des pistes de solution ».Maximum 6 personnes/groupe. Plusieurs dates sont proposées. Prix : 60 euros pour le constellé et 25 euros pour les autres participants",
//         "12:30",
//         "09:00",
//         "Matinée constellation, des racines et des ailes",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         60
//     ),
//     new Formation(
//         new Date("2023-02-13"),
//         "Il existe une symbolique pour chaque chose dans notre univers et souvent nous ne la connaissons pas et ne savons pas comment elle peut influencer notre vie. Lorsque les mots nous enferment souvent dans un sens figé, les symboles ouvrent la porte sur une richesse de significations. Notre corps s’exprime aussi à travers une symbolique qu’il nous est possible de décoder afin d’en comprendre le sens. Apprenons ensemble à lire le corps et à prendre conscience des messages qu’il nous envoie afin d’entendre lorsqu’il murmure et non lorsqu’il crie de ses maux.",
//         "15:00",
//         "09:00",
//         "Notre corps nous parle au travers de symboles",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         90
//     ),
//     new Formation(
//         new Date("2023-03-07"),
//         "Pourquoi mes choix de partenaires ne sont jamais les bons ? Pourquoi j’attire toujours des amitiés toxiques ? Pourquoi je ne sais jamais prendre de décision ?Toutes ces questions sont légitimes et les réponses sont souvent plus près qu’on ne le croit !Effectivement, notre généalogie peut influencer nos choix de vie. Cet atelier vous permettra de découvrir et comprendre comment votre place dans l’arbre généalogique détermine ce qui vous lie à certains de vos ancêtres et quelle mission vous est attribuée.La recherche et la prise de conscience des secrets de famille, des mémoires transgénérationnelles et autres liens invisibles vous permettront de vous libérer du poids d’un passé beaucoup trop lourd à porter.",
//         "15:00",
//         "10:00",
//         "Psychogénéalogie – Je prends connaissance de mon arbre généalogique. Niveau 1 ",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         90
//     ),
//     new Formation(
//         new Date("2023-03-12"),
//         "Le tarot , ce jeu de carte qui suscite en nous mystère, magie et fascination se révèle un formidable outil de développement personnel. Loin des clichés de madame Irma, la cartomancienne qui dans sa roulote vous prédisait l’avenir, ce jeu de cartes est au contraire un puissant allié qui vous permet de mettre en évidence vos points forts ou à travailler. Au travers de l’histoire et de la magie de ses 22 arcanes majeurs, vous découvrirez comment le Tarot vous guidera dans la connaissance de vous-même et de votre chemin de vie. Nous apprendrons à l’utiliser de manière simple afin qu’il vous épaule au mieux dans vos questionnements quotidiens.",
//         "17:00",
//         "10:00",
//         "Découverte du Tarot de Marseille comme outil de développement personnel : partie 2",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),
//     new Formation(
//         new Date("2023-03-19"),
//         "Le tambour est l’allié du pouvoir principal du thérapeute en pratique chamanique. A travers son langage vibratoire universel et puissant, il permet une reconnexion à l’énergie du cœur et à notre pouvoir créateur. Quelques exemples de ses multiples usages : soin d’harmonisation des Chakras, soin des polarités, soin renaissance ou chamanique. Venez découvrir cet univers et les bienfaits de cette pratique ancestrale",
//         "17:00",
//         "10:00",
//         "A la découverte de votre potentiel de guérison grâce au tambour Chamanique",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),
//     new Formation(
//         new Date("2023-04-30"),
//         "Au travers d'une interaction de groupe et du champ morphique qui s'installe entre les participants, nous découvrirons des pistes de résolution concernant différentes problématiques. Cette thérapie brève permet de travailler une émotion, un conflit, des relations problématiques. Max 6 pers/groupe.  Prix : 60 euros pour le constellé et 25 euros pour les autres participants.",
//         "17:30",
//         "14:00",
//         "Après-midi Constellation",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         60
//     ),
//     new Formation(
//         new Date("2023-04-16"),
//         "Les rayons sont des puissances énergétiques de l'univers dotés d'une force vibratoire particulière, d'une conscience et de pouvoirs propres à chacun. Ils possèdent des qualités de transmutation et un de leur pouvoir est d'agir au coeur même des cellules afin de permettre une restructuration positive. Ils agissent donc pour notre bien-être et pour le retour à la santé. Cet atelier sera organisé en deux étapes, une partie théorique et ensuite de la pratique. ",
//         "17:00",
//         "10:00",
//         "Initiation aux rayons guérisseurs",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),
//     new Formation(
//         new Date("2023-03-26"),
//         "Cette pratique de thérapie brève vous permettra de mettre en évidence des conflits personnels et intergénérationnels issus de l’inconscient familial.Il est possible de consteller une émotion, une situation, un conflit,…En effet, ce rendez-vous de groupe permettra aux différents intervenants, le constellateur, le constellé et les figurants de travailler une problématique choisie. Le champ morphique donne la possibilité à tous d’entrer dans le monde merveilleux de la constellation et de trouver des réponses et des pistes de solution. Maximum 6 personnes/groupe. Plusieurs dates sont proposées. Prix : 60 euros pour le constellé et 25 euros pour les autres participants.",
//         "12:30",
//         "09:00",
//         "Matinée constellation, des racines et des ailes",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         60
//     ),
//     new Formation(
//         new Date("2023-05-13"),
//         "Nous apprendrons comment le pendule, cet objet mystérieux qui a traversé les âges, peut nous aider à améliorer notre confort quotidien par un questionnement adapté.  Nous verrons aussi comment s'ancrer, s'aligner et se nettoyer  énergétiquement de façon efficace.",
//         "16:00",
//         "10:00",
//         "Découverte du pendule et des énergies subtiles (partie 1)",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),
//     new Formation(
//         new Date("2023-04-02"),
//         "Le bio-décodage est une approche qui permet de mieux comprendre les déséquilibres de votre corps, qu’ils soient physiques ou émotionnels. Notre cerveau inconscient nous envoie un message derrière chaque problématique que nous rencontrons, le décoder permet d’ouvrir le champ des possibles pour en sortir. Nous cheminerons au travers du langage de la « mal a dit » afin de prendre conscience que ce qui nous arrive est un message et qu’il nous appartient de le découvrir et d’en comprendre le sens.Cette prise de conscience libératrice est un beau complément à la pratique médicale.",
//         "16:00",
//         "10:00",
//         "Bio décodage niveau 1 – la prise de conscience",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),
//     new Formation(
//         new Date("2023-04-04"),
//         "Ce deuxième rendez-vous permettra de réaliser des exercices pratiques qui vous feront prendre conscience de certains conflits généalogiques inconscients. Des exercices tels que l’arbre minute et d’autres mettront sur papier ce que votre inconscient vous dicte au quotidien dans vos choix. Cette pratique est révélatrice et permettra d’ouvrir des pistes de solution et de libération. Des actes psychomagiques vous seront aussi proposés afin de sortir de certains conflits et de faire la paix avec vous m’aime.",
//         "15:00",
//         "10:00",
//         "Psychogénéalogie niveau 2 – la pratique",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         80
//     ),
//     new Formation(
//         new Date("2023-04-23"),
//         "Les diapasons sont de merveilleux outils qui permettent d’harmoniser vos énergies et de lever des blocages émotionnels et physiques. Vous pouvez les utiliser sur vous-même pour votre confort personnel, diminuer votre stress, soulager vos tensions et vous réaligner au niveau énergétique. Leur principe d’action est vibratoire, en effet, ils émettent des vibrations qui vont entrer en résonnance avec les vôtres afin d’apporter un bien-être profond.",
//         "15:00",
//         "10:00",
//         "La découverte des diapasons thérapeutiques",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         90
//     ),
//     new Formation(
//         new Date("2023-05-21"),
//         " Les bols chantants sont de merveilleux outils de relaxation, de lâcher-prise et d’harmonisation. Ils émettent des vibrations lorsqu’on les fait tinter.Ils agissent tout d’abord grâce aux ondes sonores qui parviennent au cerveau via le nerf auditif. Les sons riches et graves apaisent, favorisent le lâcher-prise et plongent l’esprit dans un état méditatif. D’autre part, lorsque le bol est posé sur une partie du corps, les ondes vibratoires vont se propager dans tous les tissus notamment grâce à l’eau de nos cellules. Ils apportent un bien-être profond tant au niveau physique qu’émotionnel. Vous apprendrez des auto-soins au bol et comment harmoniser l’aura. ",
//         "17:00",
//         "13:00",
//         "Sons et vibrations : la découverte des bols chantants",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         60
//     ),
//     new Formation(
//         new Date("2023-06-04"),
//         "Le bio-décodage est une approche qui permet de mieux comprendre les déséquilibres de votre corps, qu’ils soient physiques ou émotionnels. Notre cerveau inconscient nous envoie un message derrière chaque problématique que nous rencontrons, le décoder permet d’ouvrir le champ des possibles pour en sortir. Dans cette seconde partie nous explorerons le projet sens, cette étape fabuleuse autour de la naissance de l’individu qui déterminera en grande partie ses choix de vie. Le projet sens mettra aussi en évidence l’influence de la généalogie dans notre incarnation ",
//         "16:00",
//         "10:00",
//         "Bio décodage niveau 2 – le projet sens",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),
//     new Formation(
//         new Date("2023-06-11"),
//         "Nous continuerons notre apprentissage  des énergies subtiles avec le pendule égyptien et les différentes perturbations énergétiques qui peuvent être rencontrées.  Nous verrons comment les identifier et s'en protéger efficacement. Nous aborderons aussi comment charger et envoyer les énergies.",
//         "16:00",
//         "10:00",
//         "Découverte du pendule et des énergies subtiles (partie II)",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         100
//     ),
//     new Formation(
//         new Date("2023-06-18"),
//         "Cette pratique de thérapie brève vous permettra de mettre en évidence des conflits personnels et intergénérationnels issus de l’inconscient familial.Il est possible de consteller une émotion, une situation, un conflit,…En effet, ce rendez-vous de groupe permettra aux différents intervenants, le constellateur, le constellé et les figurants de travailler une problématique choisie. Le champ morphique donne la possibilité à tous d’entrer dans le monde merveilleux de la constellation et de trouver des réponses et des pistes de solution. Maximum 6 personnes/groupe. Plusieurs dates sont proposées. Prix : 60 euros pour le constellé et 25 euros pour les autres participants",
//         "12:30",
//         "09:00",
//         "Matinée constellation, des racines et des ailes",
//         "Rue du Ry Oursel 142, 6001 Marcinelle",
//         60
//     ),
// ];

export const rfFormations: IFormation[] = [
    new Formation(
        new Date("2023-09-03"),
        "La numérologie c’est l’étude des nombres qui gouvernent notre vie. Nous prendrons connaissance de ceux-ci pour obtenir des éléments sur la connaissance de notre chemin de vie et de qui nous sommes vraiment! Grâce à votre date de naissance, votre nom et prénom vous pourrez découvrir vos atouts et vos points à travailler. Votre profil numérologique se compose des éléments suivants :" +
            " •	le nombre personnel afin d'avoir des indications sur nous-même" +
            " •	le chemin de vie qui nous apporte des informations sur notre avenir" +
            " •	la valeur de notre prénom pour connaître plus en détails notre caractère et tempérament." +
            "Décodons ensemble les messages que l’Univers nous adresse via les nombres.",
        "16:00",
        "10:00",
        "Initiation à la numérologie",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        90
    ),
    new Formation(
        new Date("2023-09-09"),
        "Ce troisième rendez-vous du cours pendule sera principalement axé sur la pratique. Chacun fera le point sur son avancement et ses expériences. Un rappel des notions de bases sera réalisé (ancrage, conventions, nettoyages énergétiques de base). Nous partagerons ensuite encore quelques planches d’exercices.",
        "16:00",
        "13:00",
        "Pendule niveau 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        60
    ),
    new Formation(
        new Date("2023-09-10"),
        "Pour ce troisième rendez-vous, nous explorerons toujours la relation entre notre conscient et inconscient. Quel est le message que notre cerveau automatique veut nous montrer? Quel est le sens de notre problématique? Une fois que l’Homme décode le message de son inconscient et comprend le sens de son existence, il devient le « maître de son destin ». Les conflits de « nid » et les atteintes cutanées retiendront notre attention ainsi que les outils de questionnement pour cibler la problématique.",
        "16:00",
        "10:00",
        "Prise de conscience partie 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2023-09-12"),
        "Les élixirs floraux sont de subtils extraits liquides préparés par infusion solaire de fleurs sauvages ou cultivées (dont les fleurs de Bach). Ces substances sont énergétiques et vibratoires. Ces élixirs agissent de façon assez spectaculaire sur nos émotions et nos blocages inconscients, ils nous libèrent en douceur et à notre rythme. C’est une méthode tout à fait naturelle et sans accoutumance. Ils soignent notre esprit, notre cœur et notre âme par leur pouvoir vibratoire exceptionnel. Nous les découvrirons et apprendrons à décoder nos émotions afin de choisir celles dont nous avons besoin. Un oracle de ces élixirs sera également présenté et utilisé.",
        "16:00",
        "10:00",
        "Découverte des élixirs floraux",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        90
    ),
    new Formation(
        new Date("2023-09-23"),
        "Cet atelier permettra aux personnes ayant suivi les ateliers « prises de conscience » de faire le lien entre les principes vus ensemble et la loi des 5 éléments de la médecine traditionnelle chinoise. En effet, les 5 éléments que sont le Feu, la Terre, le Métal, l’Eau et le Bois viendront nous en apprendre davantage sur le morphotype de l’individu. En fonction de cette morphotypologie, l’individu sera d’avantage sensible à certaines saisons, à certains aliments, à certains comportements et à certaines maladies. Un test pour déterminer le profil de chacun sera réalisé et pourra également vous servir de base de travail lors de soins énergétiques. Cet outil nous permettra de trouver ce qui sera susceptible de nous émouvoir ou nous ébranler dans le but de trouver la solution de libération adéquate.",
        "16:00",
        "10:00",
        "Le biodécodage et la loi des 5 éléments",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2023-09-24"),
        "Nous apprendrons comment le pendule, cet objet mystérieux qui a traversé les âges, peut nous aider à améliorer notre confort quotidien par un questionnement adapté. Nous nous questionnerons sur le type de pendule qui vous conviendra le mieux et la convention mentale avec ce dernier. Nous verrons ce que sont les énergies subtiles, comment elles s’organisent autour de nous et quelles sont leurs interactions avec notre corps physique? Une technique d’ancrage et d’alignement vous sera enseignée ainsi que des techniques de nettoyage énergétique.",
        "12:30",
        "09:30",
        "Découverte du pendule et des énergies subtiles niveau 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2023-10-01"),
        "Et s’il existait un sens à chaque chose qui nous arrive? Si ce n’était pas le fruit du hasard ou un coup du destin? Derrière chaque déséquilibre que nous rencontrons au fil de la vie se cache un message de notre cerveau inconscient qu'il nous appartient d’analyser et de décoder. Nous découvrirons ensemble des pistes de décodage et de compréhension des évènements difficiles de notre quotidien afin de nous en libérer et retrouver l’équilibre tant souhaité. Cette prise de conscience sur le sens de ce qui nous arrive nous permet effectivement de lâcher prise et ensuite de pouvoir entamer un processus de guérison émotionnelle.",
        "16:00",
        "10:00",
        "Prise de conscience niveau 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2023-10-07"),
        "Un week-end de lâcher-prise, de découverte et de bien-être rien que pour vous ! C’est ce que nous vous organisons Antonella (Eveil à Soi) et moi-même (Romina) pour les 7 et 8 octobre!\nDeux jours et une nuit dans un cadre campagnard, calme et tranquille, dans une atmosphère spirituelle, de découverte de Soi et de lâcher-prise sur le quotidien.  \nAu programme : Séance de Yoga, voyage sonore, soins aux bols chantants et diapasons, séances de tarots-oracles, constellation de groupe, soins énergétiques et marche en plein air pour une reconnexion à la Terre-Mère. De la slow cosmétique (repartez avec votre composition), et de l’olfactothérapie. La nuitée, les repas et boissonssont compris.",
        "16:00",
        "10:00",
        "Un chemin vers Soi, week-end bien-être",
        "à communiquer ultérieurement",
        290
    ),
    new Formation(
        new Date("2023-10-08"),
        "Un week-end de lâcher-prise, de découverte et de bien-être rien que pour vous ! C’est ce que nous vous organisons Antonella (Eveil à Soi) et moi-même (Romina) pour les 7 et 8 octobre!\nDeux jours et une nuit dans un cadre campagnard, calme et tranquille, dans une atmosphère spirituelle, de découverte de Soi et de lâcher-prise sur le quotidien.  \nAu programme : Séance de Yoga, voyage sonore, soins aux bols chantants et diapasons, séances de tarots-oracles, constellation de groupe, soins énergétiques et marche en plein air pour une reconnexion à la Terre-Mère. De la slow cosmétique (repartez avec votre composition), et de l’olfactothérapie. La nuitée, les repas et boissonssont compris.",
        "16:00",
        "10:00",
        "Un chemin vers Soi, week-end bien-être",
        "à communiquer ultérieurement",
        290
    ),
    new Formation(
        new Date("2023-10-22"),
        "Dans cet atelier, nous reverrons les principes de base du niveau 1 et ensuite nous continuerons notre apprentissage des énergies subtiles avec le pendule égyptien. Nous prendrons connaissance des différentes perturbations énergétiques qui peuvent être rencontrées lors des tests. Le chargement et l’envoi de fréquences via ce pendule vous sera présenté et vous pourrez réaliser des tests individuels.  Je vous propose un apprentissage de la géométrie sacrée et de ses symboles, des supports qui viendront amplifier votre travail énergétique.",
        "16:00",
        "10:00",
        "Découverte du pendule et des énergies subtiles niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2023-11-12"),
        "Le prise de conscience est un acte qui permet de mieux comprendre et se rendre compte des déséquilibres de notre corps, qu’ils soient physiques ou émotionnels. Notre cerveau inconscient nous envoie un message derrière chaque problématique que nous rencontrons, le décoder permet d’ouvrir le champ des possibles pour en sortir. Dans cette seconde partie nous explorerons le projet sens, cette étape fabuleuse autour de la naissance de l’individu qui déterminera en grande partie ses choix de vie. Quel est le sens de notre vie? C’est la question à laquelle nous sommes tous amenés à répondre tôt ou tard. Le projet sens mettra aussi en évidence l’influence de la généalogie dans notre incarnation.",
        "16:00",
        "10:00",
        "Prise de conscience 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2023-11-19"),
        "Une constellation c’est avant tout une expérience relationnelle et émotionnelle. Vous partez en voyage à la rencontre de vous-même et de votre histoire familiale. C’est un travail de groupe dans lequel chacun a un rôle bien précis à jouer. Que l’on soit participant ou bien que l’on constelle une problématique, on bénéficiera de l’énergie du champ morphique qui s’installe au sein du groupe, celui-ci viendra montrer ce qui doit être vu et nous fera entendre ce qui doit être entendu. C’est une mise en évidence par un tableau vivant de notre positionnement dans notre histoire familiale et dans la vie en général. Une expérience humaine merveilleuse et porteuse de vérité.\nPrix en conscience.",
        "12:30",
        "09:30",
        "Constellation de groupe",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        0
    ),
    new Formation(
        new Date("2023-11-26"),
        "Connues depuis les civilisations anciennes et aujourd'hui largement utilisées, les huiles essentielles sont des extraits naturels de plantes d'une extraordinaire efficacité.Dans le domaine du bien-être et de la beauté elles sont devenues incontournables. En les combinant, il est possible de mettre au point des synergies aromatiques inédites dont les vertus et propriétés répondent à des besoins ciblés comme, par exemple la relaxation, la stimulation, le soulagement de douleurs articulaires et musculaires. Nous verrons comment les utiliser en olfactothérapie pour améliorer votre bien-être au quotidien.",
        "13:30",
        "09:30",
        "❌(ANNULÉ) : Découverte des huiles essentielles et de l’olfactothérapie ❌",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        0
    ),
    new Formation(
        new Date("2023-12-03"),
        "Un marché de Noël pour trouver les cadeaux de fin d’année pour vos proches. Des produits bien-être, des pierres naturelles, des bols chantants, de l’ésotérisme, tout pour gâter les êtres qui vous sont chers. Vos achats dans une ambiance festive et conviviale 😁",
        "20:00",
        "10:00",
        "Marché de Noël de la boutique",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        0
    ),
    new Formation(
        new Date("2023-12-10"),
        "Pour ce troisième rendez-vous, nous explorerons toujours la relation entre notre conscient et inconscient. Quel est le message que notre cerveau automatique veut nous montrer? Quel est le sens de ce que nous vivons?\nUne fois que l’Homme décode le message de son inconscient, de ce qui lui arrive, il devient le « maître de son destin ». \nLes conflits de « nid » et les atteintes cutanées retiendront notre attention ainsi que les outils de questionnement pour cibler la problématique.",
        "16:00",
        "10:00",
        "Prise de conscience partie 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    //2024
    new Formation(
        new Date("2024-01-14"),
        "Le Tarot de Marseille, ce jeu de carte qui suscite en nous mystère, magie et fascination se révèle un formidable outil de développement personnel. Loin des clichés de madame Irma, la cartomancienne qui dans sa roulote vous prédisait l’avenir, ce jeu de cartes est au contraire un puissant allié qui vous permet de mettre en évidence vos points forts et ceux à travailler. Au travers de son histoire et de la magie de ses 22 arcanes majeurs, vous découvrirez comment le Tarot vous guidera dans la connaissance de vous-même et de votre chemin de vie. Vous apprendrez à l’utiliser de manière simple afin qu’il vous épaule au mieux dans vos questionnements quotidiens.Cet atelier se déroulera en 3 journées : la théorie les 2 premières journées et la pratique la troisième",
        "16:00",
        "10:00",
        "Découverte du Tarot de Marseille comme outil de développement personnel : Niveau 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        110
    ),
    new Formation(
        new Date("2024-02-11"),
        "Le Tarot de Marseille, ce jeu de carte qui suscite en nous mystère, magie et fascination se révèle un formidable outil de développement personnel. Loin des clichés de madame Irma, la cartomancienne qui dans sa roulote vous prédisait l’avenir, ce jeu de cartes est au contraire un puissant allié qui vous permet de mettre en évidence vos points forts et ceux à travailler. Au travers de son histoire et de la magie de ses 22 arcanes majeurs, vous découvrirez comment le Tarot vous guidera dans la connaissance de vous-même et de votre chemin de vie. Vous apprendrez à l’utiliser de manière simple afin qu’il vous épaule au mieux dans vos questionnements quotidiens.Cet atelier se déroulera en 3 journées : la théorie les 2 premières journées et la pratique la troisième",
        "17:00",
        "10:00",
        "Découverte du Tarot de Marseille comme outil de développement personnel : Niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        110
    ),
    new Formation(
        new Date("2024-04-14"),
        "Le Tarot de Marseille, ce jeu de carte qui suscite en nous mystère, magie et fascination se révèle un formidable outil de développement personnel. Loin des clichés de madame Irma, la cartomancienne qui dans sa roulote vous prédisait l’avenir, ce jeu de cartes est au contraire un puissant allié qui vous permet de mettre en évidence vos points forts et ceux à travailler. Au travers de son histoire et de la magie de ses 22 arcanes majeurs, vous découvrirez comment le Tarot vous guidera dans la connaissance de vous-même et de votre chemin de vie. Vous apprendrez à l’utiliser de manière simple afin qu’il vous épaule au mieux dans vos questionnements quotidiens.Cet atelier se déroulera en 3 journées : la théorie les 2 premières journées et la pratique la troisième",
        "17:00",
        "10:00",
        "Découverte du Tarot de Marseille comme outil de développement personnel : Niveau 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        110
    ),
    new Formation(
        new Date("2024-01-28"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers. PRIX : 120 euros la journée complète et 60 euros la demi-journée (14h00-18h00)",
        "16:00",
        "10:00",
        "Découverte du pendule et des énergies subtiles : Niveau 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        0
    ),
    new Formation(
        new Date("2024-02-25"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers. PRIX : 120 euros la journée complète et 60 euros la demi-journée (14h00-18h00)",
        "16:00",
        "10:00",
        "Découverte du pendule et des énergies subtiles : Niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        0
    ),
    new Formation(
        new Date("2024-03-16"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers.",
        "18:00",
        "14:00",
        "Découverte du pendule et des énergies subtiles : Niveau 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        60
    ),
    new Formation(
        new Date("2024-02-10"),
        "Cet atelier vous fera découvrir ce qu’est votre taux vibratoire et ce qui le caractérise. Plus vous  élevez ce taux et plus vos ressentis s’accentuent, plus vous pourrez vous protéger de pollutions énergétiques. Les cristaux ont un rôle important dans cette mission car ils émettent des vibrations qui vont entrer en résonnance avec les vôtres et vous apporter un complément d’ énergie dont vous avez besoin à un moment précis. Vous êtes thérapeute et vous pratiquez des soins énergétiques, ils vous épauleront au quotidien dans votre travail. Ces merveilleux outils que sont les cristaux vous révèleront leurs pouvoirs au fil de cet atelier ",
        "16:00",
        "10:00",
        "Les cristaux, accueillir leur merveilleux pouvoir vibratoire",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-03-17"),
        "Cet atelier vous initiera à la connaissance des nombres. Ils rythment notre vie depuis notre naissance par les dates, les heures, et les chiffres qui nous accompagnent au quotidien sur notre chemin. Chaque nombre a une symbolique et une vibration particulière que nous découvrirons ensemble. Nous prendrons connaissance de techniques de calcul pour connaître votre chemin de vie, votre nombre personnel qui exprimera le sens de votre mission d’âme. Nous verrons également comment les nombres pourront vous aider dans vos choix de vie.",
        "16:00",
        "10:00",
        "Initiation à la numérologie",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-03-24"),
        "Nous serons en duo (Nathalie Mocavero et moi-même) pour cet atelier afin de vous proposer d’explorer votre pouvoir intérieur, de découvrir vos infinies possibilités. Nous verrons tout d’abord comment les fonctionnements liés à nos croyances limitantes nous freinent dans notre évolution et quels sont les clés pour en sortir. Entre prises de conscience, méditation guidée avec des cristaux et techniques d’alignement vous rencontrez votre puissance intérieure et la magie présente en chacun de nous.",
        "16:00",
        "10:00",
        "J’accueille mon pouvoir intérieur : quand l’âme-agit“",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-04-07"),
        "Les diapasons sont de merveilleux outils de bien-être qui permettent d’harmoniser vos énergies et de lever des blocages émotionnels et physiques. Vous pouvez les utiliser sur vous-même ou bien sur d’autres personnes afin de diminuer le stress, soulager les tensions et réaligner au niveau énergétique. Leur principe d’action est vibratoire, en effet, ils émettent des vibrations qui vont entrer en résonnance avec les vôtres dans  le but de libérer ce qui doit l’être et permettre une circulation fluide de l’énergie.",
        "16:00",
        "10:00",
        "La découverte des diapasons thérapeutiques ",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-04-21"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers. PRIX : 120 euros la journée complète et 60 euros la demi-journée (14h00-18h00)",
        "18:00",
        "10:00",
        "Découverte du pendule et des énergies subtiles : Niveau 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        0
    ),
    new Formation(
        new Date("2024-05-19"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers. PRIX : 120 euros la journée complète et 60 euros la demi-journée (14h00-18h00)",
        "18:00",
        "10:00",
        "Découverte du pendule et des énergies subtiles : Niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        0
    ),
    new Formation(
        new Date("2024-06-02"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers. PRIX : 60 euros la demi-journée",
        "18:00",
        "14:00",
        "Découverte du pendule et des énergies subtiles : Niveau 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        60
    ),
    new Formation(
        new Date("2024-05-12"),
        "Dans cet atelier donné par Nathalie Mocavero, nous tenterons de déceler et  comprendre les mécanismes qui régissent nos peurs : essayer de les reconnaître pour mieux les cerner afin de nous permettre de les « apprivoiser ». pour ce faire, nous ferons un petit détour par la compréhension des blessures sur lesquelles elles s’appuient pour se nourrir et nous paralyser, nous empêcher de penser, ou encore nous priver de notre pouvoir d’agir… Ainsi, en nous mettant en chemin vers la récupération de notre pouvoir, nous offrons à notre part de Divin l’opportunité de reprendre sa place légitime, celle qui nous est offerte dès la naissance.",
        "17:00",
        "10:00",
        "En chemin pour apprivoiser nos peurs par-delà nos blessures ",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        90
    ),
    new Formation(
        new Date("2024-06-16"),
        "Pourquoi mes choix de partenaires ne sont jamais les bons ? Pourquoi j’attire toujours des amitiés toxiques ? Pourquoi je ne sais jamais prendre de décision ? Toutes ces questions sont légitimes et les réponses sont souvent plus près qu’on ne le croit ! Effectivement, notre généalogie influence nos choix de vie. Cet atelier vous permettra de découvrir et comprendre comment votre place dans l’arbre généalogique détermine ce qui vous lie à certains de vos ancêtres et quelle mission vous est attribuée. La recherche et la  prise de conscience des secrets de famille, des mémoires transgénérationnelles et autres liens invisibles vous permettront de vous libérer du poids d’un passé beaucoup trop lourd à porter.",
        "16:00",
        "10:00",
        "Psychogénéalogie – Je prends connaissance de mon arbre généalogique",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-09-08"),
        "Cet atelier de Bio-décodage se déroulera en 3 journées.  Celles-ci vont vous permettre de prendre conscience et de mieux comprendre le fonctionnement de l’ inconscient humain dans des situations de stress. En effet, l’individu fonctionne avec des ressentis et lorsque ceux-ci sont trop puissants et ingérables par notre conscience, ils vont souvent déclencher une réponse dans notre corps physique. Dès lors, il nous appartient de décoder le sens de cette manifestation afin de pouvoir lever « le blocage » et revenir à l’équilibre énergétique. De cette façon, une circulation fluide de l’énergie vitale se rétablit. Grace à cette démarche de décodage du sens des maux,  la paix entre l’esprit, le cœur et le corps peut de nouveau s’installer. Nous visiterons également la merveilleuse période du projet sens.  De la prise de conscience à la guérison dans la matière, un merveilleux voyage au cœur de vous-m’aime",
        "16:00",
        "10:00",
        "Bio-décodage : niveau 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-10-06"),
        "Cet atelier de Bio-décodage se déroulera en 3 journées.  Celles-ci vont vous permettre de prendre conscience et de mieux comprendre le fonctionnement de l’ inconscient humain dans des situations de stress. En effet, l’individu fonctionne avec des ressentis et lorsque ceux-ci sont trop puissants et ingérables par notre conscience, ils vont souvent déclencher une réponse dans notre corps physique. Dès lors, il nous appartient de décoder le sens de cette manifestation afin de pouvoir lever « le blocage » et revenir à l’équilibre énergétique. De cette façon, une circulation fluide de l’énergie vitale se rétablit. Grace à cette démarche de décodage du sens des maux,  la paix entre l’esprit, le cœur et le corps peut de nouveau s’installer. Nous visiterons également la merveilleuse période du projet sens.  De la prise de conscience à la guérison dans la matière, un merveilleux voyage au cœur de vous-m’aime",
        "16:00",
        "10:00",
        "Bio-décodage : niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-11-10"),
        "Cet atelier de Bio-décodage se déroulera en 3 journées.  Celles-ci vont vous permettre de prendre conscience et de mieux comprendre le fonctionnement de l’ inconscient humain dans des situations de stress. En effet, l’individu fonctionne avec des ressentis et lorsque ceux-ci sont trop puissants et ingérables par notre conscience, ils vont souvent déclencher une réponse dans notre corps physique. Dès lors, il nous appartient de décoder le sens de cette manifestation afin de pouvoir lever « le blocage » et revenir à l’équilibre énergétique. De cette façon, une circulation fluide de l’énergie vitale se rétablit. Grace à cette démarche de décodage du sens des maux,  la paix entre l’esprit, le cœur et le corps peut de nouveau s’installer. Nous visiterons également la merveilleuse période du projet sens.  De la prise de conscience à la guérison dans la matière, un merveilleux voyage au cœur de vous-m’aime",
        "16:00",
        "10:00",
        "Bio-décodage : niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        100
    ),
    new Formation(
        new Date("2024-09-22"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2024-10-20"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2024-11-24"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2024-12-15"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 4",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2025-01-12"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 5",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2025-02-16"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 6",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2025-03-15"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 7",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2025-03-16"),
        "Cette formation aura pour but de vous donner les outils pour comprendre les besoins et accompagner l’individu dans la recherche de son mieux-être. Une panoplie d’outils vous seront présentés tels que les tests vibratoires, les sons et vibrations, l’utilisation des huiles essentielles, la lithothérapie, le sens des maux, le Tarot, la psychogénéalogie, les relations karmiques,… La théorie et les exercices pratiques s’alterneront pour vous permettre d’acquérir toutes ces notions et pouvoir ensuite les appliquer. Au total 9 journées seront au programme avec en bonus une sortie en nature pour prendre en conscience notre connexion au vivant. Une aventure humaine, une foule de partages et un cursus bienveillant vous attendent. Soirée de remise des certificats de participation au terme des 9 journées. La date de la neuvième journée bonus gratuite à définir.",
        "16:00",
        "10:00",
        "Accompagnateur du Vivant : journée 8",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        130
    ),
    new Formation(
        new Date("2024-09-29"),
        "Ce second atelier de psychogénéalogie est réservé aux personnes ayant déjà suivi le premier niveau et sera concentré sur la pratique. Comment à travers des exercices, votre inconscient va s’exprimer et vous permettre de prendre conscience des traumatismes que votre lignée vous a transmis. En toute bienveillance et à votre rythme, vous pourrez, avec les outils proposés, vous libérer de schémas répétitifs toxiques et de poids familiaux trop lourds à porter.",
        "15:00",
        "10:00",
        "Psychogénéalogie – Je prends connaissance de mon arbre généalogique niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        90
    ),
    new Formation(
        new Date("2024-10-05"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Ensuite au niveau 2, le pendule égyptien vous sera proposé. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers.",
        "16:00",
        "10:00",
        "Découverte du pendule et des énergies subtiles : niveau 1",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        120
    ),
    new Formation(
        new Date("2024-11-17"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Ensuite au niveau 2, le pendule égyptien vous sera proposé. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers.",
        "16:00",
        "10:00",
        "Découverte du pendule et des énergies subtiles : niveau 2",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        120
    ),
    new Formation(
        new Date("2024-12-08"),
        "La découverte du monde subtil, énergétique et vibratoire est essentielle pour vous permettre de maîtriser les techniques d’ancrage, d’alignement et de nettoyage de pollution énergétique. Vous serez capable ensuite de prendre en main le pendule afin d’apprendre le questionnement. Ensuite au niveau 2, le pendule égyptien vous sera proposé. Cet objet fascinant et quelque peu mystique vous permettra de trouver des réponses à vos questions de la vie de tous les jours. Vous apprendrez à l’utiliser afin qu’il vous accompagne dans votre développement personnel et spirituel. Cet atelier se donnera en 3 journées (2 journées complètes et une demi-journée) afin d’apprendre à votre rythme et pouvoir vous exercer entre les ateliers.",
        "18:00",
        "14:00",
        "Découverte du pendule et des énergies subtiles : niveau 3",
        "Rue du Ry Oursel 142, 6001 Marcinelle",
        60
    ),
    // new Formation(
    //     new Date("2024--"),
    //     "",
    //     "16:00",
    //     "10:00",
    //     "",
    //     "Rue du Ry Oursel 142, 6001 Marcinelle",
    //     0
    // ),
    // new Formation(
    //     new Date("2024--"),
    //     "",
    //     "16:00",
    //     "10:00",
    //     "",
    //     "Rue du Ry Oursel 142, 6001 Marcinelle",
    //     0
    // ),
];


































































