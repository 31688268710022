import formation01 from "../assets/formations/formations01.jpeg";
import image01 from "../assets/shop/image01.jpeg";
import image02 from "../assets/shop/image02.jpeg";
import image04 from "../assets/shop/image04.jpeg";
import image05 from "../assets/shop/image05.jpeg";
import shop01 from "../assets/shop/shop01.jpeg";
import shop02 from "../assets/shop/shop02.jpeg";
import shop03 from "../assets/shop/shop03.jpeg";
import shop05 from "../assets/shop/shop05.jpeg";
import shop06 from "../assets/shop/shop06.jpeg";
import shop07 from "../assets/shop/shop07.jpeg";
import shop08 from "../assets/shop/shop08.jpeg";
import img1 from "../assets/shop/img1.jpg";
import img2 from "../assets/shop/img2.jpg";
import img3 from "../assets/shop/img3.jpg";
import img4 from "../assets/shop/img4.jpg";
import img5 from "../assets/shop/img5.jpg";
import img6 from "../assets/shop/img6.jpg";
import img7 from "../assets/shop/img7.jpg";
import therapy01 from "../assets/therapy/therapy01.jpeg";

const imagesShop = [
    {
        url: img4,
        title: "Bagues et pierres",
        width: "30%",
    },
    {
        url: img6,
        title: "Bague",
        width: "15%",
    },

    {
        url: img7,
        title: "",
        width: "30%",
    },
    {
        url: shop05,
        title: "Sphère",
        width: "20%",
    },
    {
        url: img5,
        title: "Pendules",
        width: "20%",
    },
    {
        url: img2,
        title: "Bol tibétain",
        width: "25%",
    },
    {
        url: img1,
        title: "",
        width: "25%",
    },
    {
        url: img3,
        title: "Diapasons",
        width: "25%",
    },
];

const imagesFormations = [
    {
        url: formation01,
        title: "Calendrier des ateliers bien-être",
        width: "100%",
    },
    {
        url: image04,
        title: "Pendule",
        width: "33%",
    },
];

const imagesTherapy = [
    {
        url: therapy01,
        title: "Entraide",
        width: "100%",
    },
    {
        url: image05,
        title: "Sauge et pierres",
        width: "33%",
    },
    {
        url: image01,
        title: "Sauge et pierres",
        width: "33%",
    },
];

export { imagesShop, imagesFormations, imagesTherapy };
