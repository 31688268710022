import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import FormationsCalendar from "./components/CalendarFormations/FormationsCalendar";
import { Layout } from "./components/layouts/Layout";
import Formations from "./components/Views/Formations";
import Shop from "./components/Views/Shop";
import Therapy from "./components/Views/Therapy";
import Welcome from "./components/Welcome";

function App() {
    return (
        <div className="App">
            <Router basename={process.env.PUBLIC_URL}>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Welcome />} />

                        <Route path="/accueil" element={<Welcome />} />

                        <Route path="/ateliers" element={<Formations />} />

                        <Route path="/boutique" element={<Shop />} />

                        <Route path="/therapies" element={<Therapy />} />

                        <Route path="/calendrier" element={<FormationsCalendar />} />
                        <Route path="*" element={<Welcome />} />
                    </Routes>
                </Layout>
            </Router>
        </div>
    );
}

export default App;

