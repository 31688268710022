import { ReactChild, ReactFragment, ReactPortal } from "react";
import ResponsiveAppBar from "../ResponsiveAppBar";

interface LayoutProps {
    children: ReactChild | ReactFragment | ReactPortal;
}

export const Layout = (props: LayoutProps) => {
    return (
        <>
            <ResponsiveAppBar />
            <main>{props.children}</main>
        </>
    );
};
