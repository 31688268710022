import { Grid } from "@mui/material";
import React from "react";
import "../../styles/shop.scss";
import GlobalImagesShop from "../ChangingImages/GlobalImagesShop";
import SocialMediasFollow from "../SocialFollow/SocialMediasFollow";
export default function Shop() {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={12} lg={9} xl={9}>
                <div className="shop_intro">
                    <h1 className="shop_intro_title">Boutique RF Therapy</h1>
                    <p className="shop_intro_content">
                        Un lieu zen et cosy pour réaliser vos achats de bijoux en pierres
                        naturelles, bougies originales, ésotérisme, encens, nettoyage énergétique,
                        aromathérapie, sonothérapie. Je vous propose des conseils spécialisés en
                        tenant compte de vos besoins ou tout simplement pour trouver le cadeau
                        bien-être qui fera plaisir à vos proches.
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <GlobalImagesShop />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <div className="div_schedule">
                    <h2 className="schedule_title">Horaires d'ouverture</h2>
                    <ul className="schedule_list">
                        <li>Du mercredi au vendredi de 14H00 à 18H00</li>
                        <li>Le samedi de 10H00 à 14H00</li>
                    </ul>
                </div>
            </Grid>
            <SocialMediasFollow />
        </Grid>
    );
}

