import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import * as React from "react";
import "../styles/imageDialog.scss";
interface ImageDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    image: any;
    setImage: (image: any) => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export default function ImageDialog(props: ImageDialogProps) {
    const handleClose = () => {
        props.setImage(null);
        props.setOpen(false);
    };
    return (
        <>
            <div className="container">
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={props.open}
                    className="dialog_container"
                >
                    <DialogContent dividers>
                        <img src={props.image.url} alt="formation" className="image" />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            <span className="content">ok</span>
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </>
    );
}
