import { Box, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { imagesFormations } from "../../ressources/Images";
import "../../styles/formations.scss";
import ImageFormation from "../ChangingImages/ImageFormation";
import SocialMediasFollow from "../SocialFollow/SocialMediasFollow";
export default function Formations() {
    const navigate = useNavigate();
    const mail = "rof.francia@gmail.com";

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={3} lg={3}>
                    <img src="/medias/arbre.jpg" alt="background" className="logo" />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={9}
                    xl={9}
                    display={{ xs: "none", md: "block", lg: "block", xl: "block" }}
                >
                    <div className="formation_intro">
                        <h1 className="formation_intro_title">Ateliers bien-être</h1>
                        <p className="formation_intro_content">
                            Je vous propose des ateliers bien-être sur différents sujets tels que le
                            développement personnel, la sonothérapie, la découverte des huiles
                            essentielles, la radiesthésie,… Pour connaître le planning des prochains
                            rendez-vous veuillez cliquer sur l'image ci dessous.
                            <br /> Pour vous inscrire,{" "}
                            <a href={"mailto:" + mail} className="mail">
                                envoyez-moi un e-mail
                            </a>{" "}
                            avec votre demande. Au plaisir de partager ces expériences bien-être
                            avec vous !
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12} display={{ xs: "block", md: "none", lg: "none", xl: "none" }}>
                    <div className="formation_intro" style={{ marginTop: 0 }}>
                        <h1 className="formation_intro_title">Ateliers bien-être</h1>
                        <p className="formation_intro_content">
                            Je vous propose des ateliers bien-être sur différents sujets tels que le
                            développement personnel, la sonothérapie, la découverte des huiles
                            essentielles, la radiesthésie,… Pour connaître le planning des prochains
                            rendez-vous veuillez cliquer sur l'image ci dessous. <br />
                            Pour vous inscrire,{" "}
                            <a href={"mailto:" + mail} className="mail">
                                envoyez-moi un e-mail
                            </a>{" "}
                            avec votre demande. Au plaisir de partager ces expériences bien-être
                            avec vous !
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Container component="section" sx={{ mt: 8, mb: 2 }}>
                        <Box sx={{ mt: 7, display: "flex", flexWrap: "wrap" }}>
                            <ImageFormation image={imagesFormations[0]} />
                        </Box>
                    </Container>
                </Grid>

                <SocialMediasFollow />
            </Grid>
        </>
    );
}
