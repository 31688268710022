import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import "../../styles/formationForm.scss";
import { getFormattedDate } from "../../utils/calendarTools";
import { IFormation } from "./formations/formations";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

interface FormationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    formation: any;
    setFormation: (formation: any) => void;
}

interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export default function FormationDialog(props: FormationDialogProps) {
    const [formation, setFormation] = React.useState<IFormation>(props.formation);
    const handleClose = () => {
        props.setFormation(null);
        props.setOpen(false);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                className="dialog"
            >
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <b className="content subtitle">Description</b> : <br />
                        <span className="dialog_content content">{formation.description}</span>
                    </Typography>
                    <Typography gutterBottom>
                        <b className="content subtitle">Date</b> :{" "}
                        <span className="dialog content">{getFormattedDate(formation.date)}</span>
                    </Typography>
                    {formation.price > 0 && (
                        <Typography gutterBottom>
                            <b className="content subtitle">Prix</b> :{" "}
                            <span className="dialog content">{formation.price} euros</span>
                        </Typography>
                    )}
                    <Typography gutterBottom>
                        <b className="content subtitle">Horaire</b> :{" "}
                        <span className="dialog content">
                            {formation.startTime} - {formation.endTime}
                        </span>
                    </Typography>
                    <Typography gutterBottom>
                        <b className="content subtitle">Lieu</b> :{" "}
                        <span className="dialog content">{formation.location} </span>
                    </Typography>
                    <Button variant="outlined">
                        <a
                            href={`mailto:rof.francia@gmail.com?subject=${encodeURIComponent(
                                formation.title
                            )}`}
                            className="link sized_content"
                        >
                            Envoyer un mail à l'organisateur
                        </a>
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        <span className="content">ok</span>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
