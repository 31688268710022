import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box } from "@mui/material";
import "../../styles/formationForm.scss";
import { getDayMonth, isDateCurrent } from "../../utils/calendarTools";
interface ItemProps {
    formation: any;
    handleClickOpen: () => void;
}
export default function CalendarItem(props: ItemProps) {
    const formation = props.formation;
    return (
        <>
            {isDateCurrent(formation.date) && (
                <Box className="formation" key={formation.title + "" + Math.random()}>
                    <span className="subtitle">{getDayMonth(formation.date)} </span>:{" "}
                    {formation.title}{" "}
                    <OpenInNewIcon className="open_icon" onClick={props.handleClickOpen} />
                </Box>
            )}
        </>
    );
}
