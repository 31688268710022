import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import "../";
const ResponsiveAppBar = () => {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navLight = createTheme({
        palette: {
            mode: "light",
            primary: {
                main: "#d1ac97",
            },
        },
    });

    const goHome = () => {
        navigate("/accueil");
    };

    const goShop = () => {
        navigate("/boutique");
    };

    const goFormations = () => {
        navigate("/ateliers");
    };

    const goTherapy = () => {
        navigate("/therapies");
    };

    const goCalendar = () => {
        navigate("/calendrier");
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const fontFamily = "Apple Chancery, sans-serif";
    return (
        <ThemeProvider theme={navLight}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                        >
                            <Avatar
                                alt="RF Therapy"
                                src="./medias/arbre.jpg"
                                sx={{ width: 90, height: 90, margin: 2 }}
                            />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                }}
                            >
                                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                    <MenuItem key="Aaccueil" onClick={() => goHome()}>
                                        <Typography
                                            textAlign="center"
                                            sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
                                        >
                                            Accueil
                                        </Typography>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                    <MenuItem key="Aboutique" onClick={() => goShop()}>
                                        <Typography
                                            textAlign="center"
                                            sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
                                        >
                                            Boutique
                                        </Typography>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                    <MenuItem key="Aformations" onClick={() => goFormations()}>
                                        <Typography
                                            textAlign="center"
                                            sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
                                        >
                                            Ateliers
                                        </Typography>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                    <MenuItem key="Atherapies" onClick={() => goTherapy()}>
                                        <Typography
                                            textAlign="center"
                                            sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
                                        >
                                            Thérapies
                                        </Typography>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                    <MenuItem key="ACalendrier" onClick={() => goCalendar()}>
                                        <Typography
                                            textAlign="center"
                                            sx={{ fontFamily: fontFamily, fontWeight: "bold" }}
                                        >
                                            Calendrier
                                        </Typography>
                                    </MenuItem>
                                </Box>
                            </Menu>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: "flex", md: "none" },
                                flexGrow: 1,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            <Avatar
                                alt="RF Therapy"
                                src="./medias/arbre.jpg"
                                sx={{ width: 90, height: 90, margin: 2 }}
                            />
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                            <Button
                                key="Baccueil"
                                onClick={() => goHome()}
                                sx={{
                                    my: 2,
                                    color: "white",
                                    display: "block",
                                    fontFamily: fontFamily,
                                    fontWeight: "bold",
                                    fontSize: "large",
                                }}
                            >
                                Accueil
                            </Button>
                            <Button
                                key="Bboutique"
                                onClick={() => goShop()}
                                sx={{
                                    my: 2,
                                    color: "white",
                                    display: "block",
                                    fontFamily: fontFamily,
                                    fontWeight: "bold",
                                    fontSize: "large",
                                }}
                            >
                                Boutique
                            </Button>
                            <Button
                                key="Bformations"
                                onClick={() => goFormations()}
                                sx={{
                                    my: 2,
                                    color: "white",
                                    display: "block",
                                    fontFamily: fontFamily,
                                    fontWeight: "bold",
                                    fontSize: "large",
                                }}
                            >
                                Ateliers
                            </Button>
                            <Button
                                key="Btherapies"
                                onClick={() => goTherapy()}
                                sx={{
                                    my: 2,
                                    color: "white",
                                    display: "block",
                                    fontFamily: fontFamily,
                                    fontWeight: "bold",
                                    fontSize: "large",
                                }}
                            >
                                Thérapies
                            </Button>
                            <Button
                                key="BCalendrier"
                                onClick={() => goCalendar()}
                                sx={{
                                    my: 2,
                                    color: "white",
                                    display: "block",
                                    fontFamily: fontFamily,
                                    fontWeight: "bold",
                                    fontSize: "large",
                                }}
                            >
                                Calendrier
                            </Button>
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <Button
                                    key="Caccueuil"
                                    onClick={() => goHome()}
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: "block",
                                        fontFamily: fontFamily,
                                    }}
                                >
                                    Accueil
                                </Button>
                                <Button
                                    key="Cboutique"
                                    onClick={() => goShop()}
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: "block",
                                        fontFamily: fontFamily,
                                    }}
                                >
                                    Boutique
                                </Button>
                                <Button
                                    key="Cformations"
                                    onClick={() => goFormations()}
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: "block",
                                        fontFamily: fontFamily,
                                    }}
                                >
                                    Ateliers
                                </Button>
                                <Button
                                    key="Ctherapies"
                                    onClick={() => goTherapy()}
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: "block",
                                        fontFamily: fontFamily,
                                    }}
                                >
                                    Thérapies
                                </Button>
                                <Button
                                    key="CCalendrier"
                                    onClick={() => goCalendar()}
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: "block",
                                        fontFamily: fontFamily,
                                    }}
                                >
                                    Calendrier
                                </Button>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
};
export default ResponsiveAppBar;
