import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { imagesFormations, imagesShop, imagesTherapy } from "../ressources/Images";
import GlobalChangingImage from "./ChangingImages/GlobalChangingImage";

export default function ProductCategories() {
    const navigate = useNavigate();

    return (
        <Container component="section" sx={{ mt: 0, mb: 2 }}>
            {/* <Typography variant="h4" marked="center" align="center" component="h2">
                Nos activités
            </Typography> */}
            <Box sx={{ mt: 7, display: "flex", flexWrap: "wrap" }}>
                <GlobalChangingImage
                    key="shopComponent"
                    images={imagesShop}
                    delayInMilliSeconds={Math.floor(Math.random() * (10000 - 5000 + 1) + 5000)}
                    changingImageProps={{
                        category: "Boutique",
                        redirection: () => navigate("/boutique"),
                    }}
                />
                <GlobalChangingImage
                    key="formationsComponent"
                    images={imagesFormations}
                    delayInMilliSeconds={Math.floor(Math.random() * (10000 - 5000 + 1) + 5000)}
                    changingImageProps={{
                        category: "Ateliers bien-être",
                        redirection: () => navigate("/ateliers"),
                    }}
                />
                <GlobalChangingImage
                    images={imagesTherapy}
                    delayInMilliSeconds={Math.floor(Math.random() * (10000 - 5000 + 1) + 5000)}
                    changingImageProps={{
                        category: "Thérapies",
                        redirection: () => navigate("/therapies"),
                    }}
                />
            </Box>
        </Container>
    );
}
