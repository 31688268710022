import { Grid } from "@mui/material";
import "../styles/welcome.scss";
import ProductCategories from "./ProductCategories";
import SocialMediasFollow from "./SocialFollow/SocialMediasFollow";
export default function Welcome() {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={12} lg={9} xl={9}>
                <div className="home_intro">
                    <h1 className="home_intro_title">RF Therapy, la recherche du mieux-être !</h1>
                    <p className="home_intro_content">
                        Ma passion, la recherche du mieux-être ! L’équilibre optimal entre le corps,
                        l’âme et l’esprit est selon moi la clé de l’épanouissement et de la paix
                        intérieure. La création du centre de bien-être RF Therapy était donc une
                        évidence, un lieu de partage, d’échange où chacun trouve la pratique qui lui
                        convient et qui fait vibrer sa personne.
                        <br />
                        <div className="div_list">
                            <b>Trois activités vous sont proposées :</b>
                            <ul className="list">
                                <li>Une boutique </li>
                                <li>Des ateliers bien-être</li>
                                <li>Des séances de thérapies holistiques (sur rendez-vous)</li>
                            </ul>
                        </div>
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <ProductCategories />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <img src="/medias/arbre.jpg" alt="background" className="logo" />
            </Grid>
            <SocialMediasFollow />
        </Grid>
    );
}
