import { Box, Grid } from "@mui/material";
import React from "react";
import "../../styles/formationForm.scss";
import SocialMediasFollow from "../SocialFollow/SocialMediasFollow";
import Calendar from "./Calendar";
import FormationDialog from "./FormationDialog";
import { rfFormations } from "./formations/formations";
import { facebookLink, phone } from "../SocialFollow/SocialLinks";

export default function FormationsCalendar() {
    const [formations, setFormations] = React.useState([]);
    const [dialogFormation, setDialogFormation] = React.useState();
    const [open, setOpen] = React.useState(false);

    const subscription =
        "Votre inscription sera validée lors du paiement d’un acompte de 50 % du montant de l’atelier.";
    const endIntro =
        "En ce qui concerne le week-end bien être, un acompte de 100 euros sera demandé pour réserver votre place. Une soirée de présentation de ce week-end sera organisé en septembre pour tous les détails pratiques 😁";
    return (
        <>
            <div className="App">
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                        <Box>
                            <h1 className="title_calendar">Ateliers bien-être</h1>
                            <div className="content intro_div">
                                <div className="intro">
                                    Pour vous inscrire aux ateliers RF Therapy vous pouvez envoyer
                                    un message sur la page{" "}
                                    <a
                                        href={facebookLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="facebook_link"
                                    >
                                        Facebook
                                    </a>{" "}
                                    de la boutique ou bien me contacter au numéro :{" "}
                                    <span>
                                        <a
                                            href={"tel:" + phone}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: "#b5954c" }}
                                        >
                                            0477/66.36.18
                                        </a>
                                    </span>
                                </div>
                                <div className="intro">
                                    Votre inscription sera validée lors du paiement d’un{" "}
                                    <span style={{ color: "#b5954c" }}>acompte de 50 % </span> du
                                    montant de l’atelier.
                                </div>

                            </div>
                        </Box>
                    </Grid>
                    <Calendar formations={rfFormations} />
                </Grid>
                <div style={{ margin: "auto" }}>
                    <SocialMediasFollow />
                </div>
                {dialogFormation && (
                    <FormationDialog
                        formation={dialogFormation}
                        open={open}
                        setOpen={setOpen}
                        setFormation={setDialogFormation}
                    />
                )}
            </div>
        </>
    );
}
