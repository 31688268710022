import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "../Typography";

interface Image {
    url: string;
    width: string;
    title: string;
}
interface ImageProps {
    image: Image;
}

const ImageBackdrop = styled("div")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: "#000",
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "80vh",
    [theme.breakpoints.down("md")]: {
        width: "100% !important",
        height: 100,
    },
    "&:hover": {
        zIndex: 1,
    },
    "&:hover .imageBackdrop": {
        opacity: 0.15,
    },
    "&:hover .imageMarked": {
        opacity: 0,
    },
    "&:hover .imageTitle": {
        border: "4px solid currentColor",
    },
    "& .imageTitle": {
        position: "relative",
        padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
    },
    "& .imageMarked": {
        height: 3,
        width: 18,
        background: theme.palette.common.white,
        position: "absolute",
        bottom: -2,
        left: "calc(50% - 9px)",
        transition: theme.transitions.create("opacity"),
    },
}));
export default function ImageFormation(props: ImageProps) {
    const image = props.image;
    const navigate = useNavigate();
    const fontFamily = "Apple Chancery, sans-serif";
    return (
        <>
            <ImageIconButton
                key={image.title + Math.random()}
                style={{
                    width: image.width,
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundSize: "cover",
                        backgroundPosition: "center 40%",
                        backgroundImage: `url(${image.url})`,
                    }}
                />
                <ImageBackdrop className="imageBackdrop" />
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "common.white",
                    }}
                >
                    <Typography
                        component="h3"
                        variant="h6"
                        color="inherit"
                        className="imageTitle"
                        sx={{ fontFamily: fontFamily }}
                        onClick={() => navigate("/calendrier")}
                    >
                        {image.title}
                        <div className="imageMarked" />
                    </Typography>
                </Box>
            </ImageIconButton>
        </>
    );
}
