import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import MessageIcon from "@mui/icons-material/Message";
import { Fab } from "@mui/material";
import React from "react";
import "../../styles/socials.scss";
import Typography from "../Typography";

export default function SocialMediaFollow() {
    const instagramLink = "https://www.instagram.com/rf.therapy/";
    const facebookLink =
        "https://www.facebook.com/pages/category/Boutique-Store/RF-Th%C3%A9rapy-105279171005946/";
    const phone = "+32477663618";
    const mail = "rof.francia@gmail.com";
    const fontFamily = "Apple Chancery, sans-serif";
    return (
        <>
            <div style={{ margin: "auto" }}>
                <Typography
                    variant="h4"
                    marked="center"
                    align="center"
                    component="h2"
                    sx={{ marginTop: 5, marginBottom: 3, fontFamily: fontFamily }}
                >
                    Contacts
                </Typography>
                <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                    <Fab
                        size="large"
                        sx={{ margin: 2, backgroundColor: "#0076de96" }}
                        className="facebook"
                    >
                        <FacebookIcon />
                    </Fab>
                </a>
                <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                    <Fab size="large" sx={{ margin: 2 }} className="instagram">
                        <InstagramIcon />
                    </Fab>
                </a>
                <a href={"mailto:" + mail} target="_blank" rel="noopener noreferrer">
                    <Fab size="large" sx={{ margin: 2 }} className="mail">
                        <MailIcon />
                    </Fab>
                </a>
                <a href={"tel:" + phone} target="_blank" rel="noopener noreferrer">
                    <Fab
                        size="large"
                        sx={{ margin: 2, backgroundColor: "#34da4f" }}
                        className="message"
                    >
                        <MessageIcon />
                    </Fab>
                </a>
            </div>
        </>
    );
}
