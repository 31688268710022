import { Box, Container } from "@mui/material";
import * as React from "react";
import { imagesShop } from "../../ressources/Images";
import ImageDialog from "../ImageDialog";
import ImageShop from "./ImageShop";

export default function GlobalImagesShop() {
    const [currentImage, setCurrentImage] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (image: any) => {
        setCurrentImage(image);
        setOpen(true);
    };
    return (
        <>
            <Container component="section" sx={{ mt: 1, mb: 2 }}>
                {/* <Typography variant="h4" marked="center" align="center" component="h2">
                Nos activités
            </Typography> */}
                <Box sx={{ mt: 7, display: "flex", flexWrap: "wrap" }}>
                    {imagesShop.map((image) => (
                        <ImageShop image={image} handleClickOpen={handleClickOpen} />
                    ))}
                </Box>
            </Container>
            {currentImage && (
                <ImageDialog
                    image={currentImage}
                    open={open}
                    setOpen={setOpen}
                    setImage={setCurrentImage}
                />
            )}
        </>
    );
}
